import React, { useEffect, useState } from 'react';
import "./checkbox.css";

const Checkbox = ({labelText, checkboxId, checkboxName, checkboxValue, checkboxChecked, onChangeAction}) => {
    const [hover, setHover] = useState(false);

    const handleMouseEnter = () => {
        setHover(true);
      };
    
    const handleMouseLeave = () => {        
        setHover(false);
    };

    return(
        <div class="checkbox-wrapper-33"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <label class="checkbox">
                <input class="checkbox__trigger visuallyhidden" 
                type="checkbox"
                id={checkboxId}
                name={checkboxName}
                value={checkboxValue}
                checked={checkboxChecked}
                onChange={onChangeAction}
                />
                <span class="checkbox__symbol">
                <svg aria-hidden="true" class="icon-checkbox" width="28px" height="28px" viewBox="0 0 28 28" version="1" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 14l8 7L24 7"></path>
                </svg>
                </span>
                <a style={{ color: checkboxChecked || hover ? 'black' : '#5c5b5b'}} class="checkbox__textwrapper">{labelText}</a>
            </label>
        </div>
    );
};

export default Checkbox;
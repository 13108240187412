import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { MapPin, Phone, Mail, ExternalLink  } from 'lucide-react';
import ReactQuill from 'react-quill';
import { Helmet } from 'react-helmet-async';
import axios from './axiosConfig.js';
import 'react-quill/dist/quill.snow.css';
import './JobPosting.css'; // Create a CSS file for styling if needed

const JobPosting = () => {
  const { uuid } = useParams();
  const [jobPosting, setJobPosting] = useState(null);
  const [logoImage, setLogoImage] = useState(null);

  useEffect(() => {
    const fetchJobPosting = async () => {
      try {
        const response = await axios.get(`/jobPostings/${uuid}`, {
          headers: {
            'Authorization': undefined // Disable Authorization header for this request
          }
        });
        setJobPosting(response.data); // Set job posting data

        // If logo image is available, fetch it
        if (response.data?.logoImageFileName) {
          loadImageFromUrl(response.data.logoImageFileName);
        }
      } catch (error) {
        console.error('Error fetching job posting:', error.message);
      }
    };

    fetchJobPosting(); // Call the async function
  }, [uuid]);

  // Function to load image by file name
  const loadImageFromUrl = async (imageName) => {
    try {
      const response = await axios.get(`/images/${imageName}`, {
        responseType: 'blob' // Ensure response is treated as binary data
      });
      const imageBlob = response.data;
      const imageUrl = URL.createObjectURL(imageBlob); // Convert blob to URL
      setLogoImage(imageUrl); // Set image URL in state
    } catch (error) {
      console.error('Error loading image:', error.message);
    }
  };

  if (!jobPosting) {
    return <p className='job-posting-container'>Ładuję opis...</p>; // Show loading message while data is being fetched
  }

  return (
    <div className='job-posting-container'>
      {/* This content should me included when a link is displayed on diverse popular webservices */}
   
      <Helmet>
        <title>{jobPosting.jobTitle} - {jobPosting.companyName}</title>
        <meta name="description" content={`Praca w firmie ${jobPosting.companyName}`} />
        <meta property="og:title" content={`Praca w firmie ${jobPosting.companyName}`} data-react-helmet="true" />
        <meta property="og:site_name" content={`Praca w firmie ${jobPosting.companyName}`} data-react-helmet="true" />
        <meta property="og:image" content="/NortalenBanner.PNG" data-react-helmet="true" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://nortalen.com/job-postings/${uuid}`} />
      </Helmet>

      {/*  
      <meta property="og:type" content="website" />
              <meta property="og:title" content={jobPosting.jobTitle} />
        <meta property="og:site_name" content="Nortalen | Oferty pracy" />
        <meta property="og:url" content={`https://nortalen.com/job-postings/${uuid}`} />
        <meta property="og:image" content="%PUBLIC_URL%/person-standing.png" />
      */}

      <div className="job-header">
        {logoImage && (
          <div className="logo-container">
            <img src={logoImage} alt={`${jobPosting.companyName} Logo`} className="company-logo" />
          </div>
        )}
        <div className="job-header-text">
          <a className='job-title'>{jobPosting.jobTitle}</a>
          <a>{jobPosting.companyName}</a>
        </div>
      </div>


      <div className="info-row">
          <MapPin size={16} strokeWidth={2} style={{ marginRight: '5px' }} />
          <span className="job-posting-container-location">{jobPosting.locationDisplayName}</span>
      </div>
      <div className='job-posting-info-wrapper'>
        {jobPosting.typeOfEmployment !== 'ALL' && (
        <div className="info-row info-row-feature">
          <a>Typ umowy: </a>
          <span>{jobPosting.typeOfEmploymentDisplayName}</span>
        </div>
        )}
        {jobPosting.workingTime !== 'ALL' && (
        <div className="info-row info-row-feature">
          <a>Wymiar pracy: </a>
          <span>{jobPosting.workingTimeDisplayName}</span>
        </div>
        )}
        {jobPosting.workplace !== 'ALL' && (
        <div className="info-row info-row-feature">
          <a>Miejsce pracy </a>
          <span>{jobPosting.workplaceDisplayName}</span>
        </div>
        )}
        {jobPosting.experience !== 'ALL' && (
        <div className="info-row info-row-feature">
          <a>Wymagane doświadczenie: </a>
          <span>{jobPosting.experienceDisplayName}</span>
        </div>
        )}
        {jobPosting.onlineRecruitment !== 'ALL' && (
        <div className="info-row info-row-feature">
          <a>Rekrutacja online: </a>
          <span>{jobPosting.onlineRecruitmentDisplayName}</span>
        </div>
        )}
        {jobPosting.specialRequirements !== 'ALL' && (
        <div className="info-row info-row-feature">
          <a>Specjalne wymagania: </a>
          <span>{jobPosting.specialRequirementsDisplayName}</span>
        </div>
        )}
        {jobPosting.availability !== 'ALL' && (
        <div className="info-row info-row-feature">
          <a>Dyspozycyjność: </a>
          <span>{jobPosting.availabilityDisplayName}</span>
        </div>
        )}
        {jobPosting.managementPosition !== 'ALL' && (
        <div className="info-row info-row-feature">
          <a>Kadra kierownicza: </a>
          <span>{jobPosting.managementPositionDisplayName}</span>
        </div>
        )}
        {jobPosting.seniorCandidates !== 'ALL' && (
        <div className="info-row info-row-feature">
          <a>Zapraszamy seniorów: </a>
          <span>{jobPosting.seniorCandidatesDisplayName}</span>
        </div>
        )}
      </div>

      <div className="contact-info-section">
        <a className='contact-info-section-title'>Dane kontaktowe</a>
        <br/><br/>
        {jobPosting.telephone && (
          <div className="contact-info-row">
            <Phone size={14} strokeWidth={2} className="contact-info-icon" />
            <span>{jobPosting.telephone}</span>
          </div>
        )}

        {jobPosting.email && (
          <div className="contact-info-row">
            <Mail size={14} strokeWidth={2} className="contact-info-icon" />
            <span>{jobPosting.email}</span>
          </div>
        )}

        {jobPosting.externalLink && (
          <div className="contact-info-row">
            <button
              className="external-link-button"
              onClick={() => {
                const correctedLink = jobPosting.externalLink.startsWith('http://') || jobPosting.externalLink.startsWith('https://')
                  ? jobPosting.externalLink
                  : `http://${jobPosting.externalLink}`;
                window.open(correctedLink, '_blank');
              }}
            >
              Aplikuj przez link zewnętrzny
              <ExternalLink size={16} strokeWidth={2} className="button-icon" />
            </button>
          </div>
        )}
      </div>

      <br/>

      <div>
        <label htmlFor="description-preview">Opis</label>
      <div
        style={{ padding: "15px", backgroundColor: "#fafafa", borderRadius: "5px", fontFamily: "'Titillium Web', sans-serif" }}
        className='quill-wrapper' 
        dangerouslySetInnerHTML={{ __html: jobPosting.description }}
      />
      </div>
    </div>
  );
};

export default JobPosting;

/*
<ReactQuill
          id="description-preview"
          name="description-preview"
          theme="snow"
          value={jobPosting.description}
          readOnly={true}
          modules={{
            clipboard: {
              matchVisual: false
            }
          }}
          className="read-only-quill-editor"
        />
*/

/*
<div className="quill-wrapper">
<ReactQuill
theme="bubble"
readOnly={true}
value={jobPosting.description}
/>
</div>
*/

/*
        <ReactQuill 
        theme="bubble" 
        value={jobPosting.description} 
        readOnly 
        style={{ padding: "0px", backgroundColor: "#f9f9f9", borderRadius: "5px", fontFamily: "'Titillium Web', sans-serif" }}
        className='quill-wrapper' 
      />
*/
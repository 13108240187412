import React, { useState } from 'react';
import { Flame, Clock } from 'lucide-react';
import './SortingComponent.css';

const SortingComponent = ( { sortingOrder, changeSortingOrder } ) => {

  console.log("From inside SortingComponent the sorting order is " + sortingOrder);
  console.log("From inside SortingComponent the sorting order is " + sortingOrder === 'NEWEST');

  return (
    <div className="sorting-container">
      <button
        className={`sort-button ${sortingOrder === 'LAST_BUMPED' ? 'active' : ''}`}
        onClick={() => changeSortingOrder('LAST_BUMPED')}
      >
        <Flame className="sort-icon" size={18} />
        <span>Ostatnio podbite</span>
      </button>

      <button
        className={`sort-button ${sortingOrder === 'NEWEST' ? 'active' : ''}`}
        onClick={() => changeSortingOrder('NEWEST')}
      >
        <Clock className="sort-icon" size={18} />
        <span>Najnowsze</span>
      </button>
    </div>
  );
};

export default SortingComponent;
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import JobThumbnail from './JobThumbnail';
import { DoorOpen, AlertTriangle } from 'lucide-react'; // Import the Lucide icon
import axios from "./axiosConfig.js";
import './UsersJobPostingsPage.css';

const UsersJobPostingsPage = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [jobPostings, setJobPostings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [unauthorized, setUnauthorized] = useState(false); // Track if unauthorized

  const getToken = () => localStorage.getItem('nrtlnToken');

  useEffect(() => {
    const checkUserSignedIn = async () => {
      try {
        await axios.get('/user-signed-in', {
          headers: {
            'Authorization': `Bearer ${getToken()}`,
          },
        });
        // User is signed in, proceed with fetching job postings
        fetchJobPostings();
      } catch (err) {
        setUnauthorized(true); // Set unauthorized state
        setLoading(false); // Stop loading
        setError('Nie masz dostępu do tej strony'); // Error message in Polish
      }
    };

    const fetchJobPostings = async () => {
      setLoading(true); // Start loading
  
      try {
        const response = await axios.get(`/users/jobPostings`, {
          headers: {
            'Authorization': `Bearer ${getToken()}`,
          },
        }); // Send GET request
        setJobPostings(response.data); // Set job postings data
      } catch (err) {
        setError(err.message); // Handle error
      } finally {
        setLoading(false); // Stop loading
      }
    };
  
    checkUserSignedIn(); // Check user status on component mount
  }, [userId]);

  const handleLogout = async () => {
    try {
      await axios.post('/signout', {}, { // Send POST request to /signout
        headers: {
          'Authorization': `Bearer ${getToken()}`, // Include token if needed
        },
      });
      localStorage.removeItem('nrtlnToken'); // Remove token from localStorage
      navigate('/login'); // Redirect to login page
    } catch (error) {
      console.error('Error during logout:', error);
      // Optionally, handle logout error
    }
  };

  if (loading) return <div>Loading...</div>;
  if (unauthorized) return (
    <div className="error-message-container">
      <AlertTriangle size={48} color="red" />
      <p>{error}</p>
    </div>
  ); // Display unauthorized message with icon

  return (
    <div className="users-job-postings-container">
      <div className="logout-button-container">
        <button className='logout-button-underlined' onClick={handleLogout}>
            <DoorOpen size={22} style={{ marginRight: '8px' }} />
            Wyloguj
        </button>
      </div>
      <h3>Oferty opublikowane przez Ciebie</h3>
      <a>Kliknij na oferte by ją edytować lub usunąć</a>
      <br/><br/>
      {jobPostings.length === 0 ? (
        <p>Nie dodałeś jeszcze żadnych ofert</p>
      ) : (
        jobPostings.map((job, index) => (
          <JobThumbnail
            key={index}
            uuid={job.uuid}
            jobTitle={job.jobTitle}
            companyName={job.companyName}
            typeOfEmployment={job.typeOfEmployment}
            typeOfEmploymentDisplayName={job.typeOfEmploymentDisplayName}
            workingTime={job.workingTime}
            workingTimeDisplayName={job.workingTimeDisplayName}
            experience={job.experience}
            experienceDisplayName={job.experienceDisplayName}
            specialRequirements={job.specialRequirements}
            specialRequirementsDisplayName={job.specialRequirementsDisplayName}
            availability={job.availability}
            availabilityDisplayName={job.availabilityDisplayName}
            workplace={job.workplace}
            workplaceDisplayName={job.workplaceDisplayName}
            onlineRecruitment={job.onlineRecruitment}
            onlineRecruitmentDisplayName={job.onlineRecruitmentDisplayName}
            managementPosition={job.managementPosition}
            managementPositionDisplayName={job.managementPositionDisplayName}
            seniorCandidates={job.seniorCandidates}
            seniorCandidatesDisplayName={job.seniorCandidatesDisplayName}
            publicationDate={job.publicationDate}
            location={job.locationDisplayName}
            additionalLocationText={job.additionalLocationText}
            logoImageFileName={job.logoImageFileName}
            isEditModeOn={true}
          />
        ))
      )}
    </div>
  );
};

export default UsersJobPostingsPage;
import React, { useState, useEffect } from 'react';
import Checkbox from './Checkbox';
import './JobSearch.css'; // Import CSS file for styling
import axios from './axiosConfig.js';
import IndustryButton from './IndustryButton.js';

const JobSearch = ( { 
  setJobPostings, 
  formData,
  setFormData,
  initialFormData,
  options,
  setOptions,
  setProgressOngoing, 
  parentComponentIsOnDesktop, 
  closeParentPopupMenuComponent
} ) => {

  const [temporaryFormData, setTemporaryFormData] = useState(formData);  

  useEffect(() => {
    setProgressOngoing(true);
    const initialParams = new URLSearchParams(initialFormData);
    const updatedFormData = { ...initialFormData };
    const queryParams = new URLSearchParams(window.location.search);
    
    initialParams.forEach((value, key) => {
      if (!queryParams.has(key)) {
        queryParams.set(key, value);
      }
    });
    queryParams.forEach((value, key) => {
      updatedFormData[key] = value.includes(',') ? value.split(',') : value;;
    });

    setFormData(updatedFormData);

    const fetchData = async () => {
      try {
        const optionsResponse = await axios.get('/options', {
          headers: {
            'Authorization': undefined
          }
        });
        setOptions(optionsResponse.data);

        const postingsResponse = await axios.get(`/jobPostings?${queryParams.toString()}`, {
          headers: {
            'Authorization': undefined
          }
        });
        setJobPostings(postingsResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setProgressOngoing(false);
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    console.log("name " + name);
    console.log("value " + value);
    console.log("type " + type);
    console.log("checked " + checked);
  
    if(parentComponentIsOnDesktop) {
      let updatedFormData;
  
      if (type === 'checkbox') {
        if (checked) {
          updatedFormData = {
            ...formData,
            [name]: [...(formData[name] || []), value],
          };
        } else {
          updatedFormData = {
            ...formData,
            [name]: formData[name].filter((val) => val !== value),
          };
        }
      } else {
        updatedFormData = {
          ...formData,
          [name]: value,
        };
      }
      handleSubmit(undefined, updatedFormData);
    } else {
      let updatedTemporaryFormData;
    
      if (type === 'checkbox') {
        if (checked) {
          updatedTemporaryFormData = {
            ...temporaryFormData,
            [name]: [...(temporaryFormData[name] || []), value],
          };
        } else {
          updatedTemporaryFormData = {
            ...temporaryFormData,
            [name]: temporaryFormData[name].filter((val) => val !== value),
          };
        }
      } else {
        updatedTemporaryFormData = {
          ...temporaryFormData,
          [name]: value,
        };
      }
      setTemporaryFormData(updatedTemporaryFormData);
    }
  };

  const handleSubmit = (e, updatedFormData) => {
    if (e) {
      e.preventDefault();
    }

    const finalFormData = updatedFormData || temporaryFormData;
  
    setFormData(finalFormData);

    const queryParams = new URLSearchParams(finalFormData);
    const currentPath = window.location.pathname;
    window.history.replaceState(null, '', `${currentPath}?${queryParams.toString()}`);

    setJobPostings(null);
    setProgressOngoing(true);
  
    axios.get(`/jobPostings?${queryParams.toString()}`, {
      headers: {
        'Authorization': undefined
      }
    })
    .then(response => {
      setJobPostings(response.data);
      setProgressOngoing(false);
    })
    .catch(error => {
      console.error('Error fetching filtered job postings:', error);
    });
  
    if (closeParentPopupMenuComponent) {
      closeParentPopupMenuComponent();
    }
  };

  const handleClearAll = () => {
    if(parentComponentIsOnDesktop) {
      handleSubmit(undefined, initialFormData);
    } else {
      setTemporaryFormData(initialFormData);
    }
  };

  const isSelected = (value) => {
    if(parentComponentIsOnDesktop) {
      return formData.locations.includes(value);
    } else {
      return temporaryFormData.locations.includes(value);
    }
  };

  const industries = ["TRANSPORT", "HEALTHCARE", "EDUCATION", "CLEANING", "MANUFACTURING", "OFFICE", "GASTRONOMY", "RETAIL", "OTHER"];

  return (
    <div>
        <div className='job-search-title-area'>
            <div className='job-search-title'>
                <a>Filtry</a>
            </div>
            <div>
              <button className="clear-all-filters-button" onClick={handleClearAll}>
                Wyczyść filtry
              </button>
            </div>
        </div>    
    {/* Filters */}
    <form onSubmit={handleSubmit} className="job-search">

    <div className="desktop-mobile-rearrangement-style">

    <div className='filtering-comboboxes-ordering-style'>

      <div className="search-row">
        <div className="search-group">
          <label htmlFor="typeOfEmployment">Typ umowy</label>
          <select 
            id="typeOfEmployment" 
            name="typeOfEmployment" 
            value={parentComponentIsOnDesktop ? formData.typeOfEmployment : temporaryFormData.typeOfEmployment}  
            onChange={handleChange}
          >
            {options && options.typeOfEmploymentOptions.map(option => (
              <option key={option.value} value={option.value}>{option.displayName}</option>
            ))}
          </select>
        </div>

        <div className="search-group">
          <label htmlFor="workingTime">Wymiar pracy</label>
          <select 
            id="workingTime" 
            name="workingTime"
            value={parentComponentIsOnDesktop ? formData.workingTime : temporaryFormData.workingTime} 
            onChange={handleChange}
          >
            {options && options.workingTimeOptions.map(option => (
              <option key={option.value} value={option.value}>{option.displayName}</option>
            ))}
          </select>
        </div>

        <div className="search-group">
          <label htmlFor="experience">Wymagane doświadczenie</label>
          <select 
            id="experience" 
            name="experience" 
            value={parentComponentIsOnDesktop ? formData.experience : temporaryFormData.experience} 
            onChange={handleChange}
          >
            {options && options.experienceOptions.map(option => (
              <option key={option.value} value={option.value}>{option.displayName}</option>
            ))}
          </select>
        </div>

        <div className="search-group">
          <label htmlFor="specialRequirements">Specjalne wymagania</label>
          <select 
            id="specialRequirements" 
            name="specialRequirements" 
            value={parentComponentIsOnDesktop ? formData.specialRequirements : temporaryFormData.specialRequirements} 
            onChange={handleChange}
          >
            {options && options.specialRequirementsOptions.map(option => (
              <option key={option.value} value={option.value}>{option.displayName}</option>
            ))}
          </select>
        </div>
      </div>

      <div className="search-row">
        <div className="search-group">
          <label htmlFor="availability">Dyspozycyjność</label>
          <select 
            id="availability" 
            name="availability" 
            value={parentComponentIsOnDesktop ? formData.availability : temporaryFormData.availability} 
            onChange={handleChange}
          >
            {options && options.availabilityOptions.map(option => (
              <option key={option.value} value={option.value}>{option.displayName}</option>
            ))}
          </select>
        </div>

        <div className="search-group">
          <label htmlFor="workplace">Miejsce pracy</label>
          <select 
            id="workplace" 
            name="workplace" 
            value={parentComponentIsOnDesktop ? formData.workplace : temporaryFormData.workplace} 
            onChange={handleChange}
          >
            {options && options.workplaceOptions.map(option => (
              <option key={option.value} value={option.value}>{option.displayName}</option>
            ))}
          </select>
        </div>

        <div className="search-group">
          <label htmlFor="onlineRecruitment">Rekrutacja online</label>
          <select 
            id="onlineRecruitment" 
            name="onlineRecruitment" 
            value={parentComponentIsOnDesktop ? formData.onlineRecruitment : temporaryFormData.onlineRecruitment} 
            onChange={handleChange}
          >
            {options && options.onlineRecruitmentOptions.map(option => (
              <option key={option.value} value={option.value}>{option.displayName}</option>
            ))}
          </select>
        </div>

        <div className="search-group">
          <label htmlFor="managementPosition">Kadra kierownicza</label>
          <select 
            id="managementPosition" 
            name="managementPosition" 
            value={parentComponentIsOnDesktop ? formData.managementPosition : temporaryFormData.managementPosition} 
            onChange={handleChange}
          >
            {options && options.managementPositionOptions.map(option => (
              <option key={option.value} value={option.value}>{option.displayName}</option>
            ))}
          </select>
        </div>
        { /*    
        <div className="search-group">
          <label htmlFor="seniorCandidates">Zapraszamy seniorów</label>
          <select 
            id="seniorCandidates" 
            name="seniorCandidates" 
            value={parentComponentIsOnDesktop ? formData.seniorCandidates : temporaryFormData.seniorCandidates} 
            onChange={handleChange}
          >
            {options && options.seniorCandidatesOptions.map(option => (
              <option key={option.value} value={option.value}>{option.displayName}</option>
            ))}
          </select>
        </div>
        */ }   
      </div>

      </div>
      <div className='locations-and-industries-ordering-style'>

          <div className='search-row'>
          <div className='search-group' style={{width: "100%"}}>
          <label>Branża</label>
          <div className="industry-buttons-container">
                {industries.map((industry) => (
                    <IndustryButton 
                      name='industries' 
                      key={industry} 
                      industry={industry} 
                      formData={formData} 
                      onClickAction={handleChange}
                      parentComponentIsOnDesktop={parentComponentIsOnDesktop}
                      temporaryFormData={temporaryFormData}
                    />
                ))}
          </div>
          </div>
          </div>
          
          {/* This section up on top on mobile */}
          <div className='search-row'>
            <div className='search-group' style={{width: "100%"}}>
            <label>Lokalizacje</label>
            <div className="checkbox-inline">
              {options &&
                options.locationOptions.map((location) => (
                  <div key={location.value} style={{marginRight: "8px"}}>
                    <Checkbox 
                      labelText={location.displayName}
                      checkboxId={location.value} 
                      checkboxName='locations' 
                      checkboxValue={location.value} 
                      checkboxChecked={isSelected(location.value)} 
                      onChangeAction={handleChange}
                    />
                  </div>
                ))}
            </div>
          </div>
          </div>
      
      </div>
      
      </div>

      {/*
      <div className='search-submit-button-desktop'>
        <input type="submit" name="desktopSubmit" value="Zastosuj"/>
      </div>
      */}
      <div className='search-sumbit-button-mobile'>
        <input type="submit" name="mobileSubmit" value="Zastosuj"/>
      </div>
    </form>
    </div>
  );
};

export default JobSearch;

/*
const handleChange = (e) => {
  const { name, value, type, checked } = e.target;
  // console.log('name : ' + name);
  // console.log('value : ' + value);
  // console.log('type : ' + type);
  // console.log('checked : ' + checked);
  setFormData((prevState) => {
    if (type === 'checkbox') {
      // If it's a checkbox, handle adding/removing the value from the array
      if (checked) {
        // Add the value to the array
        return {
          ...prevState,
          [name]: [...(prevState[name] || []), value],
        };
      } else {
        // Remove the value from the array
        return {
          ...prevState,
          [name]: prevState[name].filter((val) => val !== value),
        };
      }
    } else {
      // For non-checkbox inputs, directly update the value
      return {
        ...prevState,
        [name]: value,
      };
    }
  });

};
*/

  /*
  // Implementasion of useEffect before using URI params:
  useEffect(() => {
    
    setProgressOngoing(true);

    const queryParams = new URLSearchParams(formData);

    setJobPostings(null);
    setProgressOngoing(true);

    // Fetch options data with overridden headers
    axios.get('/options', {
      headers: {
        'Authorization': undefined // Disable Authorization header for this request
      }
    })
    .then(response => setOptions(response.data))
    .catch(error => console.error('Error fetching options:', error));
  
    // Fetch job postings data with overridden headers
    axios.get(`/jobPostings?${queryParams.toString()}`, {
      headers: {
        'Authorization': undefined // Disable Authorization header for this request
      }
    })
    .then(response => {
      console.log("Set job postings: ", response.data);
      setJobPostings(response.data);
      setProgressOngoing(false);
    })
    .catch(error => console.error('Error fetching job postings:', error));

  }, []);
  */

  /*
  // Previous version of handleSumbit prior to the one that allows URI update depending on the formData state
  const handleSubmit = (e, updatedFormData) => {
    if(e) {
      e.preventDefault();
    }

    const queryParams = new URLSearchParams(updatedFormData || temporaryFormData);
    
    if(updatedFormData) {
      setFormData(updatedFormData);
    } else {
      setFormData(temporaryFormData);
    }

    setJobPostings(null);
    setProgressOngoing(true);

    axios.get(`/jobPostings?${queryParams.toString()}`, {
      headers: {
        'Authorization': undefined
      }
    })
      .then(response => {
        setJobPostings(response.data);
        setProgressOngoing(false);
      })
      .catch(error => console.error('Error fetching filtered job postings:', error));
    
    if(closeParentPopupMenuComponent) {
      closeParentPopupMenuComponent();
    }
  };
  */
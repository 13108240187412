import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { CheckCircle, XCircle } from 'lucide-react'; // Import Lucide icons for success and error
import axios from './axiosConfig.js';
import './PasswordResetForm.css';

const PasswordResetForm = () => {
  const [formData, setFormData] = useState({
    password: '',
    matchingPassword: '',
  });

  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false); // State to handle loading
  const [formVisible, setFormVisible] = useState(true); // State to control form visibility
  const navigate = useNavigate();
  const location = useLocation();

  // Extracting the uuid from the URL
  const queryParams = new URLSearchParams(location.search);
  const uuid = queryParams.get('uuid');

  console.log("UUID is " + uuid);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Update the formData state
    setFormData({
      ...formData,
      [name]: value
    });

    // Clear the error for the specific field being changed
    if (errors[name]) {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[name];
        return newErrors;
      });
    }
  };

  /*
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});
    setSuccessMessage('');
    setIsLoading(true); // Start loading

    // Prepare the data to be sent in the request
    const passwordChangeDto = {
      ...formData,
      uuid, // Including the uuid from the URL
    };

    try {
      const response = await fetch('http://localhost:8080/password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(passwordChangeDto)
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Password reset successful:', data);
        setSuccessMessage('Hasło zostało pomyślnie zresetowane.');
        setFormVisible(false); // Hide the form on success
        setTimeout(() => navigate('/login'), 3000); // Redirect after a short delay
      } else {
        const errorData = await response.json();
        // Map the errors to the state
        const errorsMap = {};
        errorData.errors.forEach((error) => {
          errorsMap[error.field] = error.message;
        });
        setErrors(errorsMap);
      }
    } catch (error) {
      console.error('Error during password reset:', error);
      setErrors({ general: 'Nie udało się połączyć z serwerem. Spróbuj ponownie później.' });
    } finally {
      setIsLoading(false); // Stop loading
    }
  };
  */

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});
    setSuccessMessage('');
    setIsLoading(true); // Start loading
  
    // Prepare the data to be sent in the request
    const passwordChangeDto = {
      ...formData,
      uuid, // Including the uuid from the URL
    };
  
    try {
      const response = await axios.post('/password', passwordChangeDto, {
        headers: {
          'Authorization': undefined // Disable Authorization header for this request
        }
      });
  
      if (response.status === 200) {
        const data = response.data;
        console.log('Password reset successful:', data);
        setSuccessMessage('Hasło zostało pomyślnie zresetowane.');
        setFormVisible(false); // Hide the form on success
        setTimeout(() => navigate('/login'), 3000); // Redirect after a short delay
      } else {
        const errorData = response.data;
        // Map the errors to the state
        const errorsMap = {};
        errorData.errors.forEach((error) => {
          errorsMap[error.field] = error.message;
        });
        setErrors(errorsMap);
      }
    } catch (error) {
      console.error('Error during password reset:', error);
  
      if (error.response && error.response.data && error.response.data.errors) {
        const errorsMap = {};
        error.response.data.errors.forEach((error) => {
          errorsMap[error.field] = error.message;
        });
        setErrors(errorsMap);
      } else {
        setErrors({ general: 'Nie udało się połączyć z serwerem. Spróbuj ponownie później.' });
      }
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  return (
    <div className="password-reset-container">
      {successMessage ? (
        <div className="success-message">
          <CheckCircle style={{ color: 'green', fontSize: '40px' }} /> {/* Success icon */}
          <p>{successMessage}</p>
        </div>
      ) : formVisible ? (
        <>
          <h3>Resetuj hasło</h3>
          {errors.general && (
            <div className="error-message">
              <XCircle style={{ color: 'red', fontSize: '40px' }} /> {/* Error icon */}
              <p>{errors.general}</p>
            </div>
          )}
          {errors.uuid && (
            <div className="error-message">
              <XCircle style={{ color: 'red', fontSize: '40px' }} /> {/* Error icon */}
              <p>{errors.uuid}</p>
              <div className="form-options">
                <Link to="/forgot-password" className="forgot-password-link">
                  Wyślij wiadomość email jeszcze raz
                </Link>
              </div>
            </div>
          )}
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="password">Nowe hasło</label>
              <input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
              />
              {errors.password && (
                <div className="error-message">
                  <XCircle style={{ color: 'red', fontSize: '20px' }} />
                  <p>{errors.password}</p>
                </div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="matchingPassword">Powtórz nowe hasło</label>
              <input
                type="password"
                id="matchingPassword"
                name="matchingPassword"
                value={formData.matchingPassword}
                onChange={handleChange}
                required
              />
              {errors.matchingPassword && (
                <div className="error-message">
                  <XCircle style={{ color: 'red', fontSize: '20px' }} />
                  <p>{errors.matchingPassword}</p>
                </div>
              )}
            </div>
            <button 
              type="submit" 
              className="reset-button" 
              disabled={isLoading} // Disable button while loading
            >
              {isLoading ? 'Wysyłanie...' : 'Zresetuj hasło'} {/* Show loading text when disabled */}
            </button>
          </form>
        </>
      ) : null}
    </div>
  );
};

export default PasswordResetForm;
import React, { useState, useEffect } from 'react';
import { MapPin, Building2 } from 'lucide-react';
import './JobThumbnail.css';
import { useNavigate } from 'react-router-dom';
import axios from "./axiosConfig.js";

const JobThumbnail = ({
  uuid, 
  jobTitle, 
  companyName, 
  typeOfEmployment,
  typeOfEmploymentDisplayName,
  workingTime,
  workingTimeDisplayName,
  experience,
  experienceDisplayName,
  specialRequirements,
  specialRequirementsDisplayName,
  availability,
  availabilityDisplayName,
  workplace,
  workplaceDisplayName,
  onlineRecruitment,
  onlineRecruitmentDisplayName,
  managementPosition,
  managementPositionDisplayName,
  seniorCandidates,
  seniorCandidatesDisplayName,
  publicationDate, 
  location,
  additionalLocationText,
  logoImageFileName, 
  isEditModeOn 
}) => {
  const navigate = useNavigate();
  const [logoImage, setLogoImage] = useState(null);
  const [errors, setErrors] = useState([]);

  // Combine location with additionalLocationText if it exists
  const fullLocation = additionalLocationText 
    ? `${location} (${additionalLocationText})` 
    : location;

  // Function to load the image from the backend
  const loadImageFromUrl = async (imageName) => {
    try {
      const response = await axios.get(`/images/${imageName}`, {
        responseType: 'blob' // Important: ensures the image is treated as binary data (blob)
      });

      const imageBlob = response.data;
      const imageUrl = URL.createObjectURL(imageBlob);
      setLogoImage(imageUrl);
    } catch (error) {
      console.error('Error loading image:', error);
      setErrors([error.message]);
    }
  };

  useEffect(() => {
    if (logoImageFileName) {
      loadImageFromUrl(logoImageFileName); // Fetch image when the component mounts or logoImageFileName changes
    }
  }, [logoImageFileName]);

  const handleThumbnailClick = () => {
    if (isEditModeOn) {
      navigate(`/jobs/edit/${uuid}`);
    } else {
      navigate(`/job-postings/${uuid}`);
    }
  };

  // Create an array of the display info rows
  const infoRows = [
    typeOfEmployment !== 'ALL' && { label: 'Typ umowy: ', value: typeOfEmploymentDisplayName },
    workingTime !== 'ALL' && { label: 'Wymiar pracy: ', value: workingTimeDisplayName },
    experience !== 'ALL' && { label: 'Wymagane doświadczenie: ', value: experienceDisplayName },
    workplace !== 'ALL' && { label: 'Miejsce pracy: ', value: workplaceDisplayName },
    specialRequirements !== 'ALL' && { label: 'Specjalne wymagania: ', value: specialRequirementsDisplayName },
    availability !== 'ALL' && { label: 'Dyspozycyjność: ', value: availabilityDisplayName },
    onlineRecruitment !== 'ALL' && { label: 'Rekrutacja online: ', value: onlineRecruitmentDisplayName },
    managementPosition !== 'ALL' && { label: 'Kadra kierownicza: ', value: managementPositionDisplayName },
    seniorCandidates !== 'ALL' && { label: 'Zapraszamy seniorów: ', value: seniorCandidatesDisplayName },
  ].filter(Boolean); // Filter out any falsey values (e.g. where the condition is 'ALL')

  // Limit to show only the first 4 rows and count the remaining
  const maxVisible = 4;
  const visibleRows = infoRows.slice(0, maxVisible);
  const remainingCount = infoRows.length - maxVisible;

  return (
    <div className="job-thumbnail" onClick={handleThumbnailClick}>
      <div className="job-thumbnail-header">
        {logoImage && (
          <img 
            src={logoImage} 
            alt={`${companyName} logo`} 
            className="job-thumbnail-logo"
          />
        )}
        <div>
          <a style={{ fontWeight: "bold" }}>{jobTitle}</a>
          <div className="info-row">
            <Building2 size={16} strokeWidth={2} style={{ marginRight: '5px' }} />
            <span>{companyName}</span>
          </div>
        </div>
      </div>

      <div className='job-thumbnail-info-wrapper'>
        {visibleRows.map((row, index) => (
          <div key={index} className="info-row info-row-feature">
            <a>{row.label}</a>
            <span>{row.value}</span>
          </div>
        ))}

        {remainingCount > 0 && (
          <div className="info-row info-row-feature">
            <a>+{remainingCount} więcej</a>
          </div>
        )}
      </div>
      <div className="info-row">
        <MapPin size={16} strokeWidth={2} style={{ marginRight: '5px' }} />
        <span>{fullLocation}</span>
      </div>
      <p style={{ fontSize: "12px" }}>Data publikacji: {publicationDate}</p>

      {errors.length > 0 && (
        <div className="error-message">
          {errors.map((error, index) => <p key={index}>{error}</p>)}
        </div>
      )}
    </div>
  );
};

export default JobThumbnail;
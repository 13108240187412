// src/axiosConfig.js
import axios from 'axios';

// Set default base URL for all requests
//axios.defaults.baseURL = 'http://192.168.178.47:8080';
//axios.defaults.baseURL = 'http://localhost:8080';
//axios.defaults.baseURL = 'http://192.168.178.22:8080';
axios.defaults.baseURL = 'https://api.nortalen.com';

// Set default headers for all requests
axios.defaults.headers.common['Content-Type'] = 'application/json';

export default axios;
import logo from './logo.svg';
import React, { useState, useRef, useEffect } from 'react';
import './App.css';
import JobSearch from './JobSearch';
import JobThumbnail from './JobThumbnail';
import Header from './Header';
import JobSearchPage from './JobSearchPage';
import JobPosting from './JobPosting';
import JobForm from './JobForm';
import LoginForm from './LoginForm';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import RegistrationForm from './RegistrationForm';
import PasswordResetRequestForm from './PasswordResetRequestForm';
import PasswordResetForm from './PasswordResetForm';
import UsersJobPostingsPage from './UsersJobPostingsPage';
import ConfirmAccount from './ConfirmAccount';
import ImageWithTextOverlay from './ImageWithTextOverlay';
import { HelmetProvider } from 'react-helmet-async';
/*
import LandingPage from './landing-page/LandingPage';
import PrivacyPolicy from './landing-page/PrivacyPolicy';
*/

function App() {
  return (
    <BrowserRouter>
      <link rel="preconnect" href="https://fonts.googleapis.com"/>
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
      <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Oswald:wght@200..700&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap" rel="stylesheet"/>
      <link href="https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap" rel="stylesheet"></link>
      <link href="https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap" rel="stylesheet"/>
      <link href="https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet"></link>
      <link href="https://fonts.googleapis.com/css2?family=Prosto+One&display=swap" rel="stylesheet"></link>
      <link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet"></link>
      <Header />
      
      <HelmetProvider>
        <Routes>
        
          <Route path="/" element={<JobSearchPage />} />
          <Route path="/login" element={<LoginForm />} />
          <Route path="/register" element={<RegistrationForm />} />
          <Route path="/forgot-password" element={<PasswordResetRequestForm />} />
          <Route path="/reset-password" element={<PasswordResetForm />} />
          <Route path="/jobs/create" element={<JobForm />} />
          <Route path="/jobs/edit/:uuid" element={<JobForm isEditModeOn={true}/>} />
          <Route path="/register" element={<RegistrationForm />} />
          <Route path="/confirm-account" element={<ConfirmAccount />} />
          <Route path="/my-job-postings" element={<UsersJobPostingsPage />} />
          <Route path="/job-postings/:uuid" element={<JobPosting />} />
          <Route path="/banner-image" element={<ImageWithTextOverlay />} />
      
          {/*}
          <Route path="/" element={<LandingPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          */}
          </Routes>
        </HelmetProvider>

    </BrowserRouter>
  );
}

export default App;

/*
- 
- Scrollup to JobForm's upperst error
- Authentication
*/

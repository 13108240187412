import React, { useState } from 'react';
import { CheckCircle, XCircle } from 'lucide-react'; // Import the CheckCircle and XCircle icons
import axios from './axiosConfig.js';
import './PasswordResetRequestForm.css'; // Import the CSS file for styling

const PasswordResetRequestForm = () => {
  const [email, setEmail] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false); // State to handle loading

  /*
  const handleSubmit = (e) => {
    e.preventDefault();

    // Reset previous messages
    setSuccessMessage('');
    setErrorMessage('');

    // Start loading
    setIsLoading(true);

    // Send POST request to reset password
    fetch('http://localhost:8080/reset-password', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }), // Convert email to JSON string
    })
      .then(response => {
        if (response.ok) {
          // If the response is ok, show the success message
          setSuccessMessage('Instrukcje dotyczące resetowania hasła zostały wysłane na Twój email.');
        } else {
          // If the response is not ok, show a generic error message
          throw new Error('Nie udało się zresetować hasła. Spróbuj ponownie później.');
        }
      })
      .catch(() => {
        // Set the error message for fetch failures
        setErrorMessage('Nie udało się połączyć z serwerem. Spróbuj ponownie później.');
      })
      .finally(() => {
        // Stop loading
        setIsLoading(false);
      });
  };
  */

  const handleSubmit = (e) => {
    e.preventDefault();
  
    // Reset previous messages
    setSuccessMessage('');
    setErrorMessage('');
  
    // Start loading
    setIsLoading(true);
  
    // Send POST request to reset password
    axios.post('/reset-password', { email }, {
      headers: {
        'Authorization': undefined // Disable Authorization header for this request
      }
    }) // Axios automatically converts the object to JSON
      .then(response => {
        if (response.status === 200) {
          // If the response is ok, show the success message
          setSuccessMessage('Instrukcje dotyczące resetowania hasła zostały wysłane na Twój email.');
        } else {
          // If the response is not ok, show a generic error message
          throw new Error('Nie udało się zresetować hasła. Spróbuj ponownie później.');
        }
      })
      .catch(error => {
        // Set the error message for request failures
        console.error('Error during password reset:', error);
        setErrorMessage('Nie udało się połączyć z serwerem. Spróbuj ponownie później.');
      })
      .finally(() => {
        // Stop loading
        setIsLoading(false);
      });
  };

  return (
    <div className="password-reset-container">
      {/* Conditionally render success or error message */}
      <h3>Reset hasła</h3>
      {successMessage ? (
        <div className="success-message">
          <CheckCircle style={{ color: 'green', fontSize: '40px' }} /> {/* Success icon */}
          <p>{successMessage}</p>
        </div>
      ) : errorMessage ? (
        <div className="error-message">
          <XCircle style={{ color: 'red', fontSize: '40px' }} /> {/* Error icon */}
          <p style={{ color: 'red', fontSize: '16px' }}>{errorMessage}</p> {/* Red text for error */}
        </div>
      ) : (
        // Render the form if there is no success or error message
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <button 
            type="submit" 
            className="reset-button" 
            disabled={isLoading} // Disable button while loading
          >
            {isLoading ? 'Wysyłanie...' : 'Wyślij'} {/* Show loading text when disabled */}
          </button>
        </form>
      )}
    </div>
  );
};

export default PasswordResetRequestForm;
import React, { useState, useEffect, useRef } from 'react';
import Alert from '@mui/material/Alert';
import { useParams, useNavigate } from 'react-router-dom';
import './JobForm.css';
import ReactQuill from 'react-quill';
import Checkbox from './Checkbox';
import axios from './axiosConfig.js';
import 'react-quill/dist/quill.snow.css';
import { Wand } from 'lucide-react';
import RestrictedDatePicker from './RestrictedDatePicker.js';

const JobForm = ({ isEditModeOn }) => {  
  const initialFormData = {
    jobTitle: '',
    description: '',
    companyName: '',
    typeOfEmployment: 'ALL',
    workingTime: 'ALL',
    experience: 'ALL',
    specialRequirements: 'ALL',
    availability: 'ALL',
    workplace: 'ALL',
    onlineRecruitment: 'ALL',
    managementPosition: 'ALL',
    seniorCandidates: 'ALL',
    locationId: undefined
  };

  const [formData, setFormData] = useState(initialFormData);
  const [options, setOptions] = useState({
    typeOfEmploymentOptions: [],
    workingTimeOptions: [],
    experienceOptions: [],
    specialRequirementsOptions: [],
    availabilityOptions: [],
    workplaceOptions: [],
    onlineRecruitmentOptions: [],
    managementPositionOptions: [],
    seniorCandidatesOptions: [],
    locationOptions: []
  });

  const [logoImage, setLogoImage] = useState(null);
  const [logoPreview, setLogoPreview] = useState(null);

  let { uuid } = useParams();
  const navigate = useNavigate();
  const getToken = () => localStorage.getItem('nrtlnToken');

  /*
  const fetchJobPostingById = (id) => {
    fetch(`http://localhost:8080/jobPostings/${id}`)
      .then(response => response.json())
      .then(data => {
        setFormData(data); // Set form data with fetched jobPosting data
      })
      .catch(error => console.error('Error fetching job posting:', error));
  };
  */

  const fetchJobPostingById = async (id) => {
    try {
      const response = await axios.get(`/jobPostings/${id}`, {
        headers: {
          'Authorization': undefined // Disable Authorization header for this request
        }
      });
      setFormData(response.data); // Set form data with fetched jobPosting data
      if(response.data && response.data.logoImageFileName) {
        loadImageFromUrl(response.data.logoImageFileName);
      }
    } catch (error) {
      console.error('Error fetching job posting:', error);
    }
  };

  /*
  useEffect(() => {
    window.scrollTo(0, 0);
    
    fetch('http://localhost:8080/options')
      .then(response => response.json())
      .then(data => setOptions(data))
      .catch(error => console.error('Error fetching options:', error));

    if(isEditModeOn) {
      fetchJobPostingById(uuid);
    }
    }, []);
  */

  const [unauthorized, setUnauthorized] = useState(true); // Track if unauthorized
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    
    const checkUserSignedIn = async () => {
      try {
        await axios.get('/user-signed-in', {
          headers: {
            'Authorization': `Bearer ${getToken()}`,
          },
        });

        setLoading(false);
        setUnauthorized(false);
        // User is signed in, proceed with fetching data
        fetchData();
      } catch (err) {
        setLoading(false);
        setUnauthorized(true); // Set unauthorized state
      }
    };

    checkUserSignedIn();

    // Function to fetch data
    const fetchData = async () => {
      try {
        window.scrollTo(0, 0);

        // Fetch options data
        const optionsResponse = await axios.get('/options', {
          headers: {
            'Authorization': undefined // Disable Authorization header for this request
          }
        });
        setOptions(optionsResponse.data);
        setLoading(false);

        // Fetch job posting by ID if in edit mode
        if (isEditModeOn) {
          await fetchJobPostingById(uuid);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    console.log("Change done for : " + name + " field with value " + value);
    console.log("Change done for field of type : " + type);
    if (name === 'telephone' && !/^\d*$/.test(value)) {
        return;
    }

    if (name === 'telephone' || name === 'email') {
      if (value.trim() === "") {
        setFormData(prevState => ({
          ...prevState,
          [name]: null
        }));
        return;
      }
    }

    setFormData(prevState => {
      if (type === 'checkbox') {
        if (checked) {
          return {
            ...prevState,
            [name]: value
          };
        } else {
          return {
            ...prevState,
            [name]: undefined
          };
        }
      } else {
        return {
          ...prevState,
          [name]: value
        };
      }
    });
  };

  const [errors, setErrors] = useState(undefined);
  const errorRefs = useRef({});

  /*
  const handleDelete = async () => {
    try {
      const response = await fetch(`http://localhost:8080/jobPostings/${uuid}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        // Navigate to another page or show a success message
        console.log("Succeeded to delete!");
        navigate(`/users/1/job-postings`);
      } else {
        // Handle errors
        console.error('Failed to delete job posting');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  */

  const handleDelete = async () => {  
    try {
      const response = await axios.delete(`/jobPostings/${uuid}`, {
        headers: {
          'Authorization': `Bearer ${getToken()}`,
        },
      });
  
      if (response.status === 200) {
        console.log("Succeeded to delete!");
        navigate(`/users/1/job-postings`);
      } else {
        console.error('Failed to delete job posting', response.statusText);
      }
    } catch (error) {
      console.error('Error:', error.message || error);
    }
  };

  /*
  const handleSubmitPut = (e) => {
    e.preventDefault();
    setErrors(undefined);
  
    fetch(`http://localhost:8080/jobPostings/${uuid}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json', // specify that the content type is JSON
      },
      body: JSON.stringify(formData), // convert form data to JSON string
    })
      .then(response => {
        if (!response.ok) {
          return response.json().then(data => {
            setErrors(data.errors);
            throw new Error(`HTTP error! Status: ${response.status}`);
          });
        }
        return response.json();
      })
      .then(data => {
        const editedJobPostingUuid = data.uuid;
        navigate(`/job-postings/${editedJobPostingUuid}`);
      })
      .catch(error => {
        console.log("Handling ERROR: ", error.message);
      });
  };*/

  const handleSubmit = (e) => {
    if(isEditModeOn) {
      handleSubmitPut(e);
    } else {
      handleSubmitPost(e);
    }
  }

  useEffect(() => {
    if (errors && errors.length > 0) {
      const { field } = errors[0]; // Get the field property from the first error
      const errorRef = errorRefs.current[field];
  
      if (errorRef) {
        //errorRef.focus(); // Focus on the specific input field
        errorRef.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [errors]); 

  /*
  const handleSubmitPost = (e) => {
    e.preventDefault();
    setErrors(undefined);

    // Retrieve the token from localStorage
    const token = localStorage.getItem('nrtlnToken');
    console.log("Token from localStorage:", token);

    axios.post('http://localhost:8080/jobPostings', formData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`  // Add Bearer token to headers
      }
    })
    .then(response => {
      console.log('Received JSON:', JSON.stringify(response.data, null, 2));
      console.log("New job postings UUID is " + response.data.uuid);
      const newJobPostingUuid = response.data.uuid;
      navigate(`/job-postings/${newJobPostingUuid}`);
    })
    .catch(error => {
      if (error.response && error.response.data) {
        setErrors(error.response.data.errors);
      }
      console.log("Handling ERROR: ", error.message);
    });
  };
  */

  const handleSubmitPost = (e) => {
    e.preventDefault();
    setErrors(undefined);

    const data = new FormData();
    const formDataToBeSent = formData;
    
    // Append the logo image to the form data if it exists
    if (logoImage) {
      data.append('logoImage', logoImage); // 'logoImage' is the name of the form field that will be sent
    }

    data.append('jobPosting', new Blob([JSON.stringify(formDataToBeSent)], { type: "application/json" }));

    axios.post('/jobPostings', data, {
      headers: {
        'Authorization': `Bearer ${getToken()}`,
        'Content-Type': 'multipart/form-data'
      },
    })
      .then(response => {
        console.log('Received JSON:', JSON.stringify(response.data, null, 2));
        console.log("New job postings UUID is " + response.data.uuid);
        const newJobPostingUuid = response.data.uuid;
        navigate(`/job-postings/${newJobPostingUuid}`);
      })
      .catch(error => {
        if (error.response && error.response.data) {
          setErrors(error.response.data.errors);
        }
        console.log("Handling ERROR: ", error.message);
      });
  };

  const handleSubmitPut = async (e) => {
    e.preventDefault();
    setErrors(undefined);

    const data = new FormData();
    const formDataToBeSent = formData;
    
    // Append the logo image to the form data if it exists
    if (logoImage) {
      data.append('logoImage', logoImage); // 'logoImage' is the name of the form field that will be sent
    }

    data.append('jobPosting', new Blob([JSON.stringify(formDataToBeSent)], { type: "application/json" }));
  
    try {
      const response = await axios.put(`/jobPostings/${uuid}`, data, {
        headers: {
          'Authorization': `Bearer ${getToken()}`,
          'Content-Type': 'multipart/form-data'
        },
      });
  
      const responseData = response.data;
      const editedJobPostingUuid = responseData.uuid;
      navigate(`/job-postings/${editedJobPostingUuid}`);
    } catch (error) {
      if (error.response && error.response.data) {
        setErrors(error.response.data.errors);
      } else {
        console.log("Handling ERROR: ", error.message || error);
      }
    }
  };

  const handleClearAll = () => {
    setFormData(initialFormData);
  };

  const isSelected = (value) => {
    console.log("Form data locationId is " + formData.locationId);
    return formData.locationId == value;
  };

  const determineLabelText = (value, displayName) => {
    return value === "ALL" ? 'Niesprecyzowano' : displayName;
  };

  const handleChangeDescription = (value) => {
    // Create a temporary div to extract inner text from the HTML string
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = value;
    const valueInnerText = tempDiv.innerText.trim();
  
    console.log("Value before: " + value);

    // Handle special case for empty editor
    if (value === '<p><br></p>') {
      value = "";
    }
  
    console.log("Final content is: " + value);
    console.log("Value length is: " + value.length);
  
    // Update state only if the value has changed
    setFormData(prevState => {
      if (prevState.description !== value) {
        return {
          ...prevState,
          description: value
        };
      }
      return prevState; // Avoid unnecessary state update
    });
  };

  // Function to handle image loading from a URL
  const loadImageFromUrl = async (imageName) => {
    try {
      // Clear any existing errors
      setErrors([]);

      // Fetch the image from the server
      const response = await axios.get(`/images/${imageName}`, {
        responseType: 'blob' // Important: ensures the image is treated as binary data (blob)
      });

      // Persist the blob as the logoImage
      const imageBlob = response.data;
      setLogoImage(imageBlob);

      // Create a preview using the image blob
      const imageUrl = URL.createObjectURL(imageBlob);
      setLogoPreview(imageUrl);

    } catch (error) {
      console.error('Error loading image:', error);
      setErrors([error.message]);
    }
  };

  // Logo upload logic
  const handleFileChangeFromUpload = (e) => {
    const file = e.target.files[0];
    
    setErrors([]);
    
    if (file) {
    
      // Set file to logoImage state
      setLogoImage(file);

      // Create preview
      const reader = new FileReader();
      reader.onload = (e) => {
        setLogoPreview(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDeleteLogo = () => {
    setLogoPreview(null);
    setLogoImage(null);
    setFormData(prevState => ({
      ...prevState,
      logoImageFileName: null
    }));
  };

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'], // Options you want to include
      [{ 'list': 'ordered' }, { 'list': 'bullet' }]
    ],
    clipboard: {
      matchVisual: false
    }
  };

  // Define formats for the editor
  const formats = [
    'header',
    'font',
    'bold',
    'italic',
    'underline',
    'list',
    'bullet',
    'link'
  ];

  const calculateDesctriptionsAvailableMemoryPercentage = () => {
    const maxLength = 8000;
    return (formData.description.length / maxLength) * 100;
  };

  const availableDescriptionMemory = calculateDesctriptionsAvailableMemoryPercentage().toFixed(2);

  if(loading) return(
    <div>Loading...</div>
  );
  if (unauthorized) return (
    <div className="access-message-container">
      <Wand size={38} strokeWidth={1.3} color="black" />
      <p>Aby opublikować ogłoszenie o pracę, musisz się zalogować lub zarejestrować</p>
      
      <br/>
      
      <a href="/login">
        <button className="login-button">
          Zaloguj się
        </button>
      </a>

      <div className="or-divider">
        <span className="line"></span>
        <span className="or-text">lub</span>
        <span className="line"></span>
      </div>

      <a href="/register">
        <button className="register-button">
          Zarejestruj się
        </button>
      </a>
    </div>
  );

  return (
    <div className='job-form-page-style'>
      <div className='job-form-title'>
        <div>
        {isEditModeOn ? 
          <a>Edytuj oferte</a>
          :
          <a>Nowa oferta</a>
        }
        </div>
        {!isEditModeOn ? 
        <div>
          <button className='button-underlined' onClick={handleClearAll}>Zresetuj dane</button>
        </div> 
        :
        <div>
          <button className='button-underlined' onClick={handleDelete}>Usun oferte</button>
        </div> 
        }
      </div>
      <form onSubmit={handleSubmit} className="job-search" target = '_self'>

        <div className="search-row">
        <div className="search-group">
          <label htmlFor="typeOfEmployment">Typ umowy</label>
          <select id="typeOfEmployment" name="typeOfEmployment" value={formData.typeOfEmployment} onChange={handleChange}>
            {options && options.typeOfEmploymentOptions.map(option => (
              <option key={option.value} value={option.value}>{determineLabelText(option.value, option.displayName)}</option>
            ))}
          </select>
        </div>

        <div className="search-group">
          <label htmlFor="workingTime">Wymiar pracy</label>
          <select id="workingTime" name="workingTime" value={formData.workingTime} onChange={handleChange}>
            {options && options.workingTimeOptions.map(option => (
              <option key={option.value} value={option.value}>{determineLabelText(option.value, option.displayName)}</option>
            ))}
          </select>
        </div>

        <div className="search-group">
          <label htmlFor="experience">Wymagane doświadczenie</label>
          <select id="experience" name="experience" value={formData.experience} onChange={handleChange}>
            {options && options.experienceOptions.map(option => (
              <option key={option.value} value={option.value}>{determineLabelText(option.value, option.displayName)}</option>
            ))}
          </select>
        </div>

        <div className="search-group">
          <label htmlFor="specialRequirements">Specjalne wymagania</label>
          <select id="specialRequirements" name="specialRequirements" value={formData.specialRequirements} onChange={handleChange}>
            {options && options.specialRequirementsOptions.map(option => (
              <option key={option.value} value={option.value}>{determineLabelText(option.value, option.displayName)}</option>
            ))}
          </select>
        </div>
      </div>

      <div className="search-row">
        <div className="search-group">
          <label htmlFor="availability">Dyspozycyjność</label>
          <select id="availability" name="availability" value={formData.availability} onChange={handleChange}>
            {options && options.availabilityOptions.map(option => (
              <option key={option.value} value={option.value}>{determineLabelText(option.value, option.displayName)}</option>
            ))}
          </select>
        </div>

        <div className="search-group">
          <label htmlFor="workplace">Miejsce pracy</label>
          <select id="workplace" name="workplace" value={formData.workplace} onChange={handleChange}>
            {options && options.workplaceOptions.map(option => (
              <option key={option.value} value={option.value}>{determineLabelText(option.value, option.displayName)}</option>
            ))}
          </select>
        </div>

        <div className="search-group">
          <label htmlFor="onlineRecruitment">Rekrutacja online</label>
          <select id="onlineRecruitment" name="onlineRecruitment" value={formData.onlineRecruitment} onChange={handleChange}>
            {options && options.onlineRecruitmentOptions.map(option => (
              <option key={option.value} value={option.value}>{determineLabelText(option.value, option.displayName)}</option>
            ))}
          </select>
        </div>

        <div className="search-group">
          <label htmlFor="managementPosition">Kadra kierownicza</label>
          <select id="managementPosition" name="managementPosition" value={formData.managementPosition} onChange={handleChange}>
            {options && options.managementPositionOptions.map(option => (
              <option key={option.value} value={option.value}>{determineLabelText(option.value, option.displayName)}</option>
            ))}
          </select>
        </div>
        { /*
        <div className="search-group">
          <label htmlFor="seniorCandidates">Zapraszamy seniorów</label>
          <select id="seniorCandidates" name="seniorCandidates" value={formData.seniorCandidates} onChange={handleChange}>
            {options && options.seniorCandidatesOptions.map(option => (
              <option key={option.value} value={option.value}>{determineLabelText(option.value, option.displayName)}</option>
            ))}
          </select>
        </div>
        */ }   
      </div>

      <div className='search-row'>
          <div className='search-group' style={{width: "100%"}}>
          <label>Lokalizacja</label>
          <div className="checkbox-inline">
          {options &&
            options.locationOptions.map((location) => (
              <div key={location.value} style={{marginRight: "8px"}}>
                <Checkbox 
                  labelText={location.displayName}
                  checkboxId={location.value} 
                  checkboxName='locationId' 
                  checkboxValue={location.value} 
                  checkboxChecked={isSelected(location.value)} 
                  onChangeAction={handleChange}
                />
              </div>
            ))}
          </div>
          {errors && errors.map(error => error.field === 'locationId' && (
            <Alert className='job-form-alert-style' variant="outlined" severity="error"  ref={el => (errorRefs.current['locationId'] = el)}>
              {error.message}
            </Alert>
          ))}
        </div>
      </div>

      <div>
            <label htmlFor="additionalLocationText">Dodatkowy opis lokalizacji</label>
            <input 
                type="text"
                id="additionalLocationText" 
                name="additionalLocationText" 
                value={formData.additionalLocationText} 
                onChange={handleChange} 
            />
            <a className="job-form-validation-base-text">Możesz dodać dodatkowy opis lokalizacji który ukaże się w nawiasie obok głównej lokalizacji.</a>
            <div>
              {errors && errors.map(error => error.field === 'additionalLocationText' && (
                <Alert className='job-form-alert-style' variant="outlined" severity="error" ref={el => (errorRefs.current['additionalLocationText'] = el)}>
                  {error.message}
                </Alert>
                ))}
            </div>
      </div>

      <br/>
        
        <div className="text-input-container">
        <div>
            <label htmlFor="companyName">Nazwa firmy</label>
            <input 
              type="text" 
              id="companyName" 
              name="companyName" 
              value={formData.companyName} 
              onChange={handleChange}
              onBlur={handleChange} 
            />
            {errors && errors.map(error => error.field === 'companyName' && (
            <Alert className='job-form-alert-style' variant="outlined" severity="error" ref={el => (errorRefs.current['companyName'] = el)}>
              {error.message}
            </Alert>
            ))}
        </div>
        <div>
            <label htmlFor="jobTitle">Nazwa stanowiska</label>
            <input 
              type="text" 
              id="jobTitle" 
              name="jobTitle" 
              value={formData.jobTitle} 
              onChange={handleChange}
              onBlur={handleChange} 
            />
            {errors && errors.map(error => error.field === 'jobTitle' && (
            <Alert className='job-form-alert-style' variant="outlined" severity="error" ref={el => (errorRefs.current['jobTitle'] = el)}>
              {error.message}
            </Alert>
            ))}
        </div>

        
          {/* Logo Upload */}
          <div className='job-form-section-title-style'>
          <label htmlFor="logoImage">Logo</label>
          {/*
            For edit mode set received form data to logoImage state and logoPreview state.
            
            When PUT is done if new image is set then formData's image name should be set to null and
            new image should be sent to the backend.

            When PUT is done and we want to preserve the original logo then formData's image name should be filled with the original
            filename and the image should be empty.
          */}
          
            {/* Logo Preview */}
            {logoPreview && (
              <div style={{ textAlign: 'center', marginTop: '10px' }}>
                <img src={logoPreview} alt="Logo Preview" style={{ maxWidth: '100%', maxHeight: '200px', objectFit: 'contain' }} />
                <button type="button" onClick={handleDeleteLogo} style={{ display: 'block', margin: '10px auto', cursor: 'pointer' }}>
                  Usuń logo
                </button>
              </div>
            )}

            {/* File input is only shown when no logo is uploaded */}
            {!logoPreview && (
              <input
                type="file"
                id="logoImage"
                name="logoImage"
                accept=".png,.jpeg,.jpg,.svg,image/png,image/jpeg,image/svg+xml"
                onChange={handleFileChangeFromUpload}
              />
            )}

          {errors && errors.map(error => error.field === 'logoImage' && (
            <Alert className='job-form-alert-style' variant="outlined" severity="error">
              {error.message}
            </Alert>
          ))}
        </div>


        <br/>
        <div className='job-form-section-title-style'>
            <a>Opis</a>
        </div>
        <div>
          <label htmlFor="description">Podgląd opisu</label>
          <div
            style={{ padding: "15px", backgroundColor: "#fafafa", borderRadius: "5px", fontFamily: "'Titillium Web', sans-serif" }}
            className='quill-preview-wrapper' 
            dangerouslySetInnerHTML={{ __html: formData.description }}
          />
        </div>
        <div>
        <div>
          <label htmlFor="description">Edytuj opis</label>
          <ReactQuill
            id="description"
            name="description"
            theme="snow" // Use the snow theme for a clean interface
            value={formData.description}
            onChange={handleChangeDescription} 
            modules={modules}
          />
          {errors && errors.map(error => error.field === 'description' && (
            <Alert className='job-form-alert-style' variant="outlined" severity="error" ref={el => (errorRefs.current['description'] = el)}>
              {error.message}
            </Alert>
          ))}
        </div>
        <div className={`job-form-validation-base-text ${availableDescriptionMemory > 100 ? 'job-form-validation-red-text' : 'job-form-validation-black-text'}`}>
          {availableDescriptionMemory}% wykorzystanego miejsca na opis
        </div>
        <br/>
        </div>

        <div className='job-form-section-title-style'>
            <a>Dane kontaktowe</a>
        </div>
        <div>
          {errors && errors.map(error => error.field === 'emailExternalLinkOrTelephone' && (
            <Alert className='job-form-alert-style' variant="outlined" severity="error" ref={el => (errorRefs.current['emailExternalLinkOrTelephone'] = el)}>
              {error.message}
            </Alert>
            ))}
        </div>
        <div>
            <label htmlFor="telephone">Numer telefonu</label>
            <input 
                type="tel"
                id="telephone"
                name="telephone"
                value={formData.telephone}
                onChange={handleChange}
                title="Please enter numbers only"
            />
        </div>
        <div>
            <label htmlFor="email">Email</label>
            <input 
                type="email"  // Use "email" input type for email addresses
                id="email" 
                name="email" 
                value={formData.email} 
                onChange={handleChange} 
            />
        </div>
        <div>
            <label htmlFor="externalLink">Link zewnętrzny</label>
            <div className="job-form-validation-base-text-container">
              <a className="job-form-validation-base-text">To link po kliknięciu którego przekierowani będą odwiedzający. Upewnij się że link prowadzi do isniejącej strony.</a>
            </div>
            <input 
                type="text"  // Use "email" input type for email addresses
                id="externalLink" 
                name="externalLink" 
                value={formData.externalLink} 
                onChange={handleChange} 
            />
             <div>
              {errors && errors.map(error => error.field === 'externalLink' && (
                <Alert className='job-form-alert-style' variant="outlined" severity="error" ref={el => (errorRefs.current['externalLink'] = el)}>
                  {error.message}
                </Alert>
                ))}
            </div>
        </div>
        </div>

        <div>
          <label htmlFor="externalLink">Data wygaśnięcia</label>
          <div className="job-form-validation-base-text-container">
            <a className="job-form-validation-base-text">To data wygaśnięcia ogłoszenia po którym zostanie automatycznie usunięte (do 60 dni od daty publikacji). Jeśli jej nie podasz ogłoszenie zostanie automatycznie usunięte za 60 dni.</a>
          </div>
          <RestrictedDatePicker 
            id="expiryDate" 
            name="expiryDate"
            value={formData.expiryDate}
            onChange={handleChange}
          />
        </div>
        
        <br/><br/>

        <div className="default-submit-button">
        <input
          type="submit"
          className="default-submit-button"
          value={isEditModeOn ? "Zapisz zmiany" : "Opublikuj ofertę"}
        />
        </div>
      
      <div className="fixed-button-container">
        <input
          type="submit"
          value={isEditModeOn ? "Zapisz zmiany" : "Opublikuj ofertę"}
          onClick={handleSubmit}
        />
      </div>
      </form>
    </div>
  );
};

export default JobForm;

/*
        <div className="read-only-quill-editor">
          <label htmlFor="description">Podgląd opisu</label>
          <ReactQuill
            id="description-preview"
            name="description-preview"
            theme="snow"
            value={formData.description}
            readOnly={true}
            modules={{
              clipboard: {
                matchVisual: false
              },
            }}
          />
          <ReactQuill theme="bubble" readOnly value={formData.description}/>
        </div>
*/

// <ReactQuill theme="bubble" readOnly value={formData.description} style={{padding: "5px"}}/>

/*
<div className="checkbox-inline">
            {options &&
              options.locationOptions.map((location) => (
                <div key={location.value} className="checkbox-item">
                  <input
                    type='checkbox'
                    id={location.value}
                    name='locationId'
                    value={location.value}
                    checked={isSelected(location.value)}
                    onChange={handleChange}
                  />
                  <label htmlFor={location.value}>{location.displayName}</label>
                </div>
              ))}
          </div>
*/
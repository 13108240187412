import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Alert } from '@mui/material'; // Import the Alert component from MUI
import { UserCheck, CircleX } from 'lucide-react'; // Import the UserCheck and CircleX icons
import axios from './axiosConfig.js';
import './LoginForm.css';

const LoginForm = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isSuccess, setIsSuccess] = useState(false); // State to handle successful login
  const [isError, setIsError] = useState(false); // State to handle unexpected errors
  const [isLoading, setIsLoading] = useState(false); // State to handle button loading

  const handleRegisterClick = () => {
    navigate('/register');
  };

  /* TO BE DELETED AFTER AXIOS VERSION IS TESTED OK
  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsSuccess(false);
    setIsError(false);
    setIsLoading(true); // Start loading

    const loginRequestDto = {
      username,
      password,
    };

    try {
      const response = await fetch('http://localhost:8080/signin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(loginRequestDto),
      });

      if (response.ok) {
        const token = await response.text();
        console.log('Login successful, token:', token);
        localStorage.setItem('nrtlnToken', token); // Save the token to localStorage
        setError('');
        setIsSuccess(true); // Indicate successful login

        // Redirect after a delay to give time for the success message to be seen
        setTimeout(() => {
          navigate('/jobs/create'); // Replace with your desired route
        }, 2000);
      } else if (response.status === 400 || response.status === 401) {
        setError('Nieprawidłowa nazwa użytkownika lub hasło, lub użytkownik wymaga potwierdzenia email.'); // Handle BadRequest or Unauthorized error
        setIsSuccess(false);
        setIsError(false);
      } else {
        setIsError(true); // Indicate unexpected errors
        setIsSuccess(false);
      }
    } catch (error) {
      console.error('Error during login:', error);
      setError('Nie udało się połączyć z serwerem. Spróbuj ponownie później.'); // Handle connectivity or other errors
      setIsSuccess(false);
      setIsError(true);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };
  */

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsSuccess(false);
    setIsError(false);
    setIsLoading(true); // Start loading
  
    const loginRequestDto = {
      username,
      password,
    };
  
    try {
      const response = await axios.post('/signin', loginRequestDto, {
        headers: {
          'Authorization': undefined // Disable Authorization header for this request
        }
      });
  
      if (response.status === 200) {
        const token = response.data; // Assuming the token is returned in the response body
        console.log('Login successful, token:', token);
        localStorage.setItem('nrtlnToken', token); // Save the token to localStorage
        setError('');
        setIsSuccess(true); // Indicate successful login
  
        // Redirect after a delay to give time for the success message to be seen
        setTimeout(() => {
          navigate('/my-job-postings'); // Replace with your desired route
        }, 2000);
      } 
    } catch (error) {
      console.error('Error during login:', error);
  
      if (error.response && (error.response.status === 400 || error.response.status === 401)) {
        setError('Nieprawidłowa nazwa użytkownika lub hasło, lub użytkownik wymaga potwierdzenia email.');
        setIsSuccess(false);
        setIsError(false);
      } else {
        setError('Nie udało się połączyć z serwerem. Spróbuj ponownie później.'); // Handle connectivity or other errors
        setIsSuccess(false);
        setIsError(true);
      }
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  const handleInputChange = (setter) => (e) => {
    setter(e.target.value);
    setError(''); // Clear the error when the user starts typing
    setIsError(false);
  };

  return (
    <div className="login-container">
      {isSuccess ? (
        <div className="success-message">
          <UserCheck style={{ color: 'green', fontSize: '40px' }} /> {/* Use UserCheck icon */}
          <p>Logowanie powiodło się! <br/> Zostaniesz wkrótce przekierowany na Twój profil użytkownika.</p>
        </div>
      ) : isError ? (
        <div className="error-message">
          <CircleX style={{ color: 'red', fontSize: '40px' }} /> {/* Use CircleX icon */}
          <p>Nie udało się zalogować. Sprawdź połączenie internetowe lub spróbuj ponownie później.</p>
        </div>
      ) : (
        <div>
          <h3>Zaloguj się</h3>
          {error && <Alert severity="error" icon={false}>{error}</Alert>} {/* Display error using MUI's Alert */}
          <form onSubmit={handleLoginSubmit}>
            <div className="form-group">
              <label htmlFor="username">Email</label>
              <input
                type="email"
                id="username"
                name="username"
                value={username}
                onChange={handleInputChange(setUsername)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Hasło</label>
              <input
                type="password"
                id="password"
                name="password"
                value={password}
                onChange={handleInputChange(setPassword)}
                required
              />
            </div>
            <div className="form-options">
              <Link to="/forgot-password" className="forgot-password-link">
                Nie pamiętam hasła
              </Link>
            </div>
            <button 
              type="submit" 
              className="login-button" 
              disabled={isLoading} // Disable button while loading
            >
              {isLoading ? 'Wysyłanie...' : 'Zaloguj'} {/* Show loading text when disabled */}
            </button>
          </form>

          <div className="or-divider">
            <span className="line"></span>
            <span className="or-text">lub</span>
            <span className="line"></span>
          </div>

          <button onClick={handleRegisterClick} className="register-button">
            Zarejestruj się
          </button>
        </div>
      )}
    </div>
  );
};

export default LoginForm;
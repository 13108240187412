import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import './CenteredLoader.css'; // Assuming you have this for global styles

const CenteredLoader = () => {
  return (
    <div className="centered-loader-container">
      <div className="loader-box">
        <CircularProgress color="inherit" />
      </div>
    </div>
  );
};

export default CenteredLoader;
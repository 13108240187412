import React from 'react';
import './ImageWithTextOverlay.css'; // Make sure you have this CSS file
import image from './Welder.jpg'; // Adjust the path based on your structure

const ImageWithTextOverlay = () => {
    return (
      <div className="image-overlay-container">
        <img src={image} alt="Overlayed" className="image-overlay-img" />
        <div className="text-container">
          <div className="image-overlay-text">Nortalen.com</div>
          <div className="subtitle-text">PRACA BEZ OGRANICZEŃ</div>
        </div>
      </div>
    );
  };

export default ImageWithTextOverlay;
import React, { useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import dayjs from 'dayjs';
import 'dayjs/locale/pl'; // Import the Polish locale

const RestrictedDatePicker = ( { value, onChange, id, name }) => {
  dayjs.locale('pl');

  const today = dayjs();

  const maxDate = today.add(60, 'day');

  const handleClear = () => {
    handleDateChange(null);
  };

  const handleDateChange = (newDate) => {
    if (newDate) {
      const dateWithoutTime = newDate.startOf('day');
      onChange({
        target: {
          name,
          value: dateWithoutTime.format('YYYY-MM-DD'),
        },
      });
    } else {
      onChange({
        target: {
          name,
          value: null,
        },
      });
    }
  };

  const valueAsDayJsObject = value ? dayjs(value) : null;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pl">
      <Stack spacing={2}>
        <DatePicker
          id={id}
          name={name}
          label="Wybierz datę wygaśnięcia" // Label in Polish
          value={valueAsDayJsObject}
          onChange={handleDateChange}
          disablePast // Disables dates in the past
          minDate={today} // Set minimum selectable date to today
          maxDate={maxDate} // Set maximum selectable date to 60 days from today
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                readOnly: true, // Make the input read-only to prevent typing
              }}
            />
          )}
        />

        {/* Clear Button */}
        <Button
          variant="outlined"
          onClick={handleClear}
          disabled={!valueAsDayJsObject}
          sx={{ textTransform: 'none', fontFamily: "'Titillium Web', sans-serif" }} // Disable automatic capitalization
        >
          Wyczyść datę
        </Button>
      </Stack>
    </LocalizationProvider>
  );
};

export default RestrictedDatePicker;
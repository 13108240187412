import React, { useState } from "react";
import { X, SlidersHorizontal } from 'lucide-react'; // Importing icons
import "./PopupMenu.css";

const PopupMenu = ({ jobSearchComponent, filtersApplied }) => {

  const [isOpen, setIsOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const toggleMenu = () => {
    if (isOpen) {
      closeMenu();
    } else {
      openMenu();
    }
  };

  const closeMenu = () => {
    setIsOpen(false);
    setTimeout(() => setIsVisible(false), 300);
  };

  const openMenu = () => {
    setIsVisible(true); // Make menu visible before opening
    setIsOpen(true); // Trigger CSS animation
  };

  // Create an array of 40 menu items
  const menuItems = Array.from({ length: 40 }, (_, index) => `Menu Item ${index + 1}`);
  
  return (
    <div className="popup-menu-container">
      <button className="popup-menu-open-button" onClick={toggleMenu}>
        <SlidersHorizontal size={20} className="popup-menu-button-icon" /> {/* Icon on the left */}
        <span className="popup-menu-text">
          Filtruj
          {filtersApplied && <span className="red-dot"></span>} {/* Conditionally render the red dot */}
        </span>
      </button>

      {isVisible && (
        <div className={`popup-menu ${isOpen ? "popup-menu-slide-in" : "popup-menu-slide-out"}`}>
          <div className="popup-menu-header">
            <span className="popup-menu-title">Filtry</span>
            <button className="popup-menu-close-button" onClick={toggleMenu}>
              <X size={28} /> {/* Close button icon */}
            </button>
          </div>

          <div className="popup-menu-content">
            {React.cloneElement(jobSearchComponent, { closeParentPopupMenuComponent: closeMenu })}
          </div>
        </div>
      )}
    </div>
  );
};

export default PopupMenu;
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import axios from './axiosConfig'; // Import the configured Axios instance
import "./Header.css"; // Import the CSS file
import { PersonStanding, CircleUserRound } from 'lucide-react';

function Header() {
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const navigate = useNavigate();
  const location = useLocation(); // Access the current location

  useEffect(() => {
    const handleScroll = () => {
      const st = window.pageYOffset || document.documentElement.scrollTop;
      if (st > lastScrollTop) {
        document.querySelector(".site-header").classList.add('hide');
      } else {
        document.querySelector(".site-header").classList.remove('hide');
      }
      setLastScrollTop(st <= 0 ? 0 : st);
    };

    window.addEventListener("scroll", handleScroll); // Add event listener
    return () => {
      window.removeEventListener("scroll", handleScroll); // Clean up
    };
  }, [lastScrollTop]);

  const getToken = () => localStorage.getItem('nrtlnToken');

  const handleUserButtonClick = async (userId) => {
    try {
      // Perform the GET request to check if the user is signed in
      navigate(`/login`);

      const response = await axios.get('/user-signed-in', {
        headers: {
          'Authorization': `Bearer ${getToken()}`,
        },
      });

      if (response.status === 200) {
        // If the response is OK, navigate to the user’s job postings
        navigate(`/my-job-postings`);
      } else {
        // If already on the /login page, reload the page
        if (location.pathname === '/login') {
          window.location.reload();
        } else {
          // Otherwise, navigate to the login page
          navigate(`/login`);
        }
      }
    } catch (error) {
      // Handle errors, such as network issues
      console.error('Error checking user sign-in status:', error);
      if (location.pathname === '/login') {
        window.location.reload();
      } else {
        navigate(`/login`);
      }
    }
  };

  return (
    <header className="site-header">
      {/* Service name */}
      <a href="/" className="service-name">
        <span>Nortalen</span>
      </a>
      {/* Content of the header */}
      <div className="service-options">
        <div>
          <a href="/jobs/create">
            <button className="button-style">Dodaj ofertę</button>
          </a>
        </div>
        <div onClick={() => handleUserButtonClick(1)} style={{ cursor: 'pointer' }}>
          <CircleUserRound className="user-button-style" size={26} color="white" strokeWidth={1.4} />
        </div>
      </div>
    </header>
  );
}

export default Header;
import React, { useState, useRef, useEffect } from 'react';
import './App.css';
import './JobSearchPage.css';
import JobSearch from './JobSearch';
import JobThumbnail from './JobThumbnail';
import PopupMenu from './PopupMenu.js';
import CenteredLoader from './CenteredLoader.js';
import axios from './axiosConfig.js';
import SortingComponent from './SortingComponent.js';

const JobSearchPage = () => {
    const defaultSortingOrder = 'LAST_BUMPED';

    const initialFormData = {
      typeOfEmployment: 'ALL',
      workingTime: 'ALL',
      experience: 'ALL',
      specialRequirements: 'ALL',
      availability: 'ALL',
      workplace: 'ALL',
      onlineRecruitment: 'ALL',
      managementPosition: 'ALL',
      seniorCandidates: 'ALL',
      locations : [],
      industries: [],
      sortingOrder: defaultSortingOrder
    };

    const [options, setOptions] = useState({
      typeOfEmploymentOptions: [],
      workingTimeOptions: [],
      experienceOptions: [],
      specialRequirementsOptions: [],
      availabilityOptions: [],
      workplaceOptions: [],
      onlineRecruitmentOptions: [],
      managementPositionOptions: [],
      seniorCandidatesOptions: [],
      locationOptions : []
    });

    const [jobPostings, setJobPostings] = useState([]);
    const [progessOngoing, setProgressOngoing] = useState(false);  
    const [formData, setFormData] = useState(initialFormData);
    const [sortingOrder, setSortingOrder] = useState(defaultSortingOrder);

    const hasAppliedFilters = (formData) => {
      // If formData is null or undefined, no filters are applied
      if (!formData) {
        return false;
      }
    
      // Check if any fields have values other than 'ALL'
      const hasNonDefaultField = Object.keys(formData).some(key => {
        // Handle undefined or null values safely
        const value = formData[key];
        if (key === 'locations') {
          // Check if locations array is defined and not empty
          return Array.isArray(value) && value.length > 0;
        }

        if (key === 'industries') {
          // Check if locations array is defined and not empty
          return Array.isArray(value) && value.length > 0;
        }

        if(key === 'sortingOrder') {
          return false;
        }
        
        // Check if field value is defined and not 'ALL'
        return value !== 'ALL' && value !== undefined && value !== null;
      });
    
      return hasNonDefaultField;
    };

    const filtersApplied = hasAppliedFilters(formData);

    const changeSortingOrder = (newSortingOrder) => {
      
      const updatedFormData = {
        ...formData,
        sortingOrder: newSortingOrder
      };

      setFormData(updatedFormData);

      const queryParams = new URLSearchParams(updatedFormData);
      const currentPath = window.location.pathname;
      window.history.replaceState(null, '', `${currentPath}?${queryParams.toString()}`);

      setJobPostings(null);
      setProgressOngoing(true);
    
      axios.get(`/jobPostings?${queryParams.toString()}`, {
        headers: {
          'Authorization': undefined
        }
      })
      .then(response => {
          setJobPostings(response.data);
          setProgressOngoing(false);
      })
      .catch(error => {
          console.error('Error fetching filtered job postings:', error);
      });
    };
        
    return(
        <div className="job-search-page-container">
        {progessOngoing && (
          <CenteredLoader/>
        )}
        <div className='popup-menu-component-placement'>
          <PopupMenu 
            jobSearchComponent={
              <JobSearch 
                setJobPostings={setJobPostings} 
                formData={formData} 
                setFormData={setFormData} 
                initialFormData={initialFormData} 
                options={options} 
                setOptions={setOptions}
                sortingOrder={sortingOrder}
                defaultSortingOrder={defaultSortingOrder}
                setProgressOngoing={setProgressOngoing}
              />} 
            filtersApplied={filtersApplied} 
          />
        </div>
        <div className='job-search-desktop-component-placement'>
          <JobSearch setJobPostings={setJobPostings} formData={formData} setFormData={setFormData} initialFormData={initialFormData} options={options} setOptions={setOptions} setProgressOngoing={setProgressOngoing} parentComponentIsOnDesktop={true}/>
        </div>
        <div>
          <SortingComponent sortingOrder={formData.sortingOrder} changeSortingOrder={changeSortingOrder} />
        </div>
        <br/>
        <div className="jobs-counter-container">
          <a>
            Ilość rezultatów <span className="jobs-counter-bold-text">{jobPostings ? jobPostings.length : 0}</span>
          </a>
        </div>
        <br/>
        <div>
        {jobPostings && jobPostings.map((job, index) => (
          <JobThumbnail
            key={index}
            uuid={job.uuid}
            jobTitle={job.jobTitle}
            companyName={job.companyName}
            typeOfEmployment={job.typeOfEmployment}
            typeOfEmploymentDisplayName={job.typeOfEmploymentDisplayName}
            workingTime={job.workingTime}
            workingTimeDisplayName={job.workingTimeDisplayName}
            experience={job.experience}
            experienceDisplayName={job.experienceDisplayName}
            specialRequirements={job.specialRequirements}
            specialRequirementsDisplayName={job.specialRequirementsDisplayName}
            availability={job.availability}
            availabilityDisplayName={job.availabilityDisplayName}
            workplace={job.workplace}
            workplaceDisplayName={job.workplaceDisplayName}
            onlineRecruitment={job.onlineRecruitment}
            onlineRecruitmentDisplayName={job.onlineRecruitmentDisplayName}
            managementPosition={job.managementPosition}
            managementPositionDisplayName={job.managementPositionDisplayName}
            seniorCandidates={job.seniorCandidates}
            seniorCandidatesDisplayName={job.seniorCandidatesDisplayName}
            publicationDate={job.publicationDate}
            location={job.locationDisplayName}
            additionalLocationText={job.additionalLocationText}
            logoImageFileName={job.logoImageFileName}
          />
        ))}
        </div>
        </div>
    );
}

export default JobSearchPage;
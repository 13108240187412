import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from './axiosConfig.js';

const ConfirmAccount = () => {
    const [status, setStatus] = useState('Przetwarzanie twojego żądania...');
    const location = useLocation();

    // Function to get the UUID from the URL query parameters
    const getUuidFromUrl = () => {
        const params = new URLSearchParams(location.search);
        return params.get('uuid');
    };

    /*
    // Function to send POST request to confirm the account
    const confirmAccount = async (uuid) => {
        try {
            const response = await fetch('http://localhost:8080/confirm-account', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: uuid,
            });

            if (response.ok) {
                setStatus('Konto zostało pomyślnie potwierdzone!');
            } else {
                const errorData = await response.json();
                if (errorData.errors && errorData.errors.length > 0) {
                    setStatus(
                        <>
                            <span style={{ fontSize: '18px' }}>
                                {errorData.errors[0].message || 'Nie udało się potwierdzić konta.'}
                            </span>
                            <br />
                            <br />
                            <Link to="/forgot-password" className="forgot-password-link" style={{ fontSize: '18px' }}>
                                Poproś o reset hasła jeszcze raz
                            </Link>
                        </>
                    );
                } else {
                    setStatus(
                        <>
                            <span style={{ fontSize: '18px' }}>
                                Nie udało się potwierdzić konta z powodu nieznanego błędu.
                            </span>
                            <br />
                            <br />
                            <Link to="/forgot-password" className="forgot-password-link" style={{ fontSize: '18px' }}>
                                Poproś o reset hasła jeszcze raz
                            </Link>
                        </>
                    );
                }
            }
        } catch (error) {
            setStatus(
                <>
                    <span style={{ fontSize: '18px' }}>
                        Błąd: Nie udało się potwierdzić konta. Spróbuj ponownie później.
                    </span>
                    <br />
                    <br />
                    <Link to="/forgot-password" className="forgot-password-link" style={{ fontSize: '18px' }}>
                        Poproś o reset hasła jeszcze raz
                    </Link>
                </>
            );
        }
    };
    */

    // Function to send POST request to confirm the account
    const confirmAccount = async (uuid) => {
        try {
            const response = await axios.post('/confirm-account', { uuid }, {
                headers: {
                  'Authorization': undefined // Disable Authorization header for this request
                }
            });

            if (response.status === 200) {
                setStatus('Konto zostało pomyślnie potwierdzone!');
            } else {
                const errorData = response.data;
                if (errorData.errors && errorData.errors.length > 0) {
                    setStatus(
                        <>
                            <span style={{ fontSize: '18px' }}>
                                {errorData.errors[0].message || 'Nie udało się potwierdzić konta.'}
                            </span>
                            <br />
                            <br />
                            <Link to="/forgot-password" className="forgot-password-link" style={{ fontSize: '18px' }}>
                                Poproś o reset hasła jeszcze raz
                            </Link>
                        </>
                    );
                } else {
                    setStatus(
                        <>
                            <span style={{ fontSize: '18px' }}>
                                Nie udało się potwierdzić konta z powodu nieznanego błędu.
                            </span>
                            <br />
                            <br />
                            <Link to="/forgot-password" className="forgot-password-link" style={{ fontSize: '18px' }}>
                                Poproś o reset hasła jeszcze raz
                            </Link>
                        </>
                    );
                }
            }
        } catch (error) {
            setStatus(
                <>
                    <span style={{ fontSize: '18px' }}>
                        Błąd: Nie udało się potwierdzić konta. Spróbuj ponownie później.
                    </span>
                    <br />
                    <br />
                    <Link to="/forgot-password" className="forgot-password-link" style={{ fontSize: '18px' }}>
                        Poproś o reset hasła jeszcze raz
                    </Link>
                </>
            );
        }
    };

    // useEffect hook to handle component mount behavior
    useEffect(() => {
        const uuid = getUuidFromUrl();
        if (uuid) {
            confirmAccount(uuid);
        } else {
            setStatus(<span style={{ fontSize: '18px' }}>Link potwierdzenia konta jest niepoprawny.</span>);
        }
    }, []); // Empty dependency array ensures this runs only once when the component mounts

    return (
        <div style={{ textAlign: 'center', paddingTop: '110px' }}>
            <h1>Potwierdzenie Konta</h1>
            <p>{status}</p>
        </div>
    );
};

export default ConfirmAccount;
import React from 'react';
import './IndustryButton.css'; // Make sure to import the CSS file


// Labels for each industry
const labelMap = {
  TRANSPORT: "Transport",
  HEALTHCARE: "Zdrowie",
  EDUCATION: "Szkolnictwo",
  CLEANING: "Sprzątanie",
  MANUFACTURING: "Produkcja",
  OFFICE: "Biuro",
  GASTRONOMY: "Gastronomia",
  RETAIL: "Handel",
  OTHER: "Inne"
};

const IndustryButton = ({ industry, name, onClickAction, formData, temporaryFormData, parentComponentIsOnDesktop }) => {
  const capitalizedIndustry = industry.toUpperCase();
  const label = labelMap[capitalizedIndustry];

  if (!label) {
    return null; // Handle unknown industry case
  }

  // Ensure that formData[name] is an array or empty by default
  const selectedIndustries = parentComponentIsOnDesktop ? formData[name] || [] : temporaryFormData[name] || [];

  // Handle the click action, mimicking checkbox behavior
  const handleClick = (e) => {
    const fakeEvent = {
      target: {
        name: name,
        value: industry,
        type: 'checkbox',
        checked: !selectedIndustries.includes(industry)
      }
    };
    onClickAction(fakeEvent);  // Trigger the passed onClickAction function with the checkbox-like event
  };

  return (
    <button
      type="button"
      className={`industry-button ${selectedIndustries.includes(industry) ? 'selected' : ''}`}
      name={name}
      onClick={handleClick}
    >
      <span>{label}</span>
    </button>
  );
};

export default IndustryButton;
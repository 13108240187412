import React, { useState } from 'react';
import { Alert } from '@mui/material';
import { MailCheck, CircleX } from 'lucide-react'; // Import the MailCheck and CircleX icons
import axios from './axiosConfig.js';
import './RegistrationForm.css'; // Import the CSS file for styling

const RegistrationForm = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    matchingPassword: '',
    nickname: ''
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false); // State to handle button disabling
  const [isSuccess, setIsSuccess] = useState(false); // State to handle successful registration
  const [isError, setIsError] = useState(false); // State to handle unexpected errors

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Update the formData state
    setFormData({
      ...formData,
      [name]: value
    });

    // Clear the error for the specific field being changed
    if (errors[name]) {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[name];
        return newErrors;
      });
    }
  };

  /*
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});
    setIsSubmitting(true); // Disable the button
    setIsError(false); // Reset error state

    try {
      const response = await fetch('http://localhost:8080/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        setIsSuccess(true); // Indicate success
      } else if (response.status === 400) {
        const errorData = await response.json();
        // Map the errors to the state
        const errorsMap = {};
        errorData.errors.forEach((error) => {
          errorsMap[error.field] = error.message;
        });
        setErrors(errorsMap);
        setIsSubmitting(false); // Re-enable the button if there are validation errors
      } else {
        setIsError(true); // Indicate unexpected errors
        setIsSubmitting(false); // Re-enable the button
      }
    } catch (error) {
      console.error('Error during registration:', error);
      setIsError(true); // Indicate connectivity or other errors
      setIsSubmitting(false); // Re-enable the button
    }
  };
  */


  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});
    setIsSubmitting(true); // Disable the button
    setIsError(false); // Reset error state
  
    try {
      const response = await axios.post('/register', formData, {
        headers: {
          'Authorization': undefined // Disable Authorization header for this request
        }
      }); // Send POST request with formData
  
      if (response.status === 200) {
        setIsSuccess(true); // Indicate success
      }
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (error.response.status === 400) {
          // Handle validation errors
          const errorData = error.response.data;
          const errorsMap = {};
          errorData.errors.forEach((error) => {
            errorsMap[error.field] = error.message;
          });
          setErrors(errorsMap);
        } else {
          setIsError(true); // Indicate unexpected errors
        }
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error during registration:', error.message);
        setIsError(true); // Indicate connectivity or other errors
      }
    } finally {
      setIsSubmitting(false); // Re-enable the button
    }
  };
  

  return (
    <div className="registration-container">
      {isSuccess ? (
        <div className="success-message">
          <MailCheck style={{ color: 'green', fontSize: '40px' }} /> {/* Use MailCheck icon */}
          <p>Sprawdź swoją skrzynkę pocztową, aby potwierdzić utworzenie konta.</p>
        </div>
      ) : isError ? (
        <div className="error-message">
          <CircleX style={{ color: 'red', fontSize: '40px' }} /> {/* Use CircleX icon */}
          <p>Nie udało się zarejestrować konta. Sprawdź połączenie internetowe lub spróbuj ponownie później.</p>
        </div>
      ) : (
        <div>
          <h3>Zarejestruj się</h3>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="nickname">Nazwa użytkownika</label>
              <input
                type="text"
                id="nickname"
                name="nickname"
                value={formData.nickname}
                onChange={handleChange}
                required
              />
              {errors.nickname && (
                <Alert severity="error" className="error" icon={false}>
                  {errors.nickname}
                </Alert>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              {errors.email && (
                <Alert severity="error" className="error" icon={false}>
                  {errors.email}
                </Alert>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="password">Hasło</label>
              <input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
              />
              {errors.password && (
                <Alert severity="error" className="error" icon={false}>
                  {errors.password}
                </Alert>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="matchingPassword">Powtórz hasło</label>
              <input
                type="password"
                id="matchingPassword"
                name="matchingPassword"
                value={formData.matchingPassword}
                onChange={handleChange}
                required
              />
              {errors.matchingPassword && (
                <Alert severity="error" className="error" icon={false}>
                  {errors.matchingPassword}
                </Alert>
              )}
            </div>
            <button 
              type="submit" 
              className="register-button" 
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Rejestracja...' : 'Zarejestruj'} {/* Show loading text when disabled */}
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default RegistrationForm;